import React from "react";
import "../App.scss";
import Content from "../components/Content";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import TopInfo from "../components/TopInfo";
import InputArea from "../components/inputArea";
import carbon from "../img/carbon.png";
import dashboard from "../img/dashboard.png";
import graphWhite from "../img/graph-white.png";
import monitor from "../img/monitor.png";
import portfolio from "../img/portfolio.png";
import resource from "../img/resource.png";

export default function Analytics() {
  const dash_form = {
    text: "Start your journey towards a greener future. Calculate your carbon footprint now and take the first step in becoming an eco-conscious global citizen.",
    arrow_title: "Start your net zero journey",
  };
  const arrow_title = { title: "Services", color: "black" };

  const top = {
    title: "ESG Analytics",
    text: "Our ESG Analytics section is your gateway to comprehensive Environmental, Social, and Governance insights. We offer a powerful suite of tools and data-driven solutions to help you navigate the ever-evolving landscape of sustainability and responsible business practices. With our cutting-edge analytics, you can assess your company's ESG performance, identify areas for improvement, and make informed decisions that align with your commitment to a more sustainable future. Whether you're an investor, corporate leader, or simply passionate about environmental issues, our platform empowers you with the knowledge and insights needed to drive positive change. Explore our ESG Analytics today and pave the way for a more sustainable and responsible tomorrow.",
    href: "#form",
    img: graphWhite
  };

  const analytic = [
    {
      img: portfolio,
      title: "Portfolio Overview",
      text: "In the Portfolio Overview section, we offer an opportunity to take a profound look at the history of your ESG (Environmental, Social, and Governance) initiatives. Here, you can meticulously dissect ESG data and performance from bygone years, allowing you to compare and contrast your sustainability efforts through detailed visual representations. This historical retrospective serves as a valuable resource for tracking your progress and plotting a course towards a more sustainable future.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
    },

    {
      img: resource,
      title: "Resource Consumption",
      text: "The Resource Consumption section presents a detailed overview of how your organization has utilized vital resources such as energy and water. Robust and comprehensive reports, complemented by visually intuitive graphics, empower you to diligently monitor your resource consumption. With this knowledge at your fingertips, you can confidently make decisions that optimize resource usage and reduce your environmental footprint.",
      side: "left",
      hex_color: "rgba(255, 255, 255, 1)",
      btn: false,
      bg: true,
    },

    {
      img: carbon,
      title: "Carbon Emissions",
      text: "Our Carbon Emissions section is your gateway to tracking and mitigating carbon emissions. We provide you with comprehensive, data-rich reports that showcase your annual and monthly carbon footprint. These insights are fundamental in devising effective strategies to reduce your environmental impact and align your organization with sustainability goals.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
      bg: false,
    },

    {
      img: monitor,
      title: "Energy Monitoring",
      text: "The Energy Monitoring section offers advanced tools for tracking your electricity consumption. You'll be able to observe real-time energy usage, analyze historical data, and identify opportunities for cost savings. Through this comprehensive view of your energy consumption patterns, you can implement measures to increase energy efficiency and reduce operational costs.",
      side: "left",
      hex_color: "rgba(255, 255, 255, 1)",
      btn: false,
      bg: true,
    },

    {
      img: dashboard,
      title: "Custom Dashboards",
      text: "In the Custom Dashboards section, we hand you the reins to create personalized control panels. This feature enables you to tailor data displays to your exact needs, allowing you to monitor specific Key Performance Indicators (KPIs) critical to your sustainability objectives. It empowers you with a user-centric approach, making the ESG Analytics tool even more flexible and suited to your unique requirements.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
      bg: false,
    },
  ];
  return (
    <div className="app">
      <Navbar />
      <TopInfo props={top} />

      {/* <Arrows arrow={arrow_title} /> */}

      <section>
        <div className="w-container">
          {analytic.map((item) => (
            <Content props={item} />
          ))}
        </div>
      </section>
      <section id="form">
        <InputArea props={dash_form} />
      </section>
      <Footer />
    </div>
  );
}
