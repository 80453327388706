import React from "react";
import Navbar from "../components/Navbar";
import TopInfo from "../components/TopInfo";
import Arrows from "../components/arrows";
import Content from "../components/Content";
import InputArea from "../components/inputArea";
import Footer from "../components/Footer";
import transition from "../img/transition.png";
import physical from "../img/physical.png";
import cloudWhite from "../img/cloud-white.png"

export default function CarbonTrejectory() {
  const dash_form = {
    text: "Start your journey towards a greener future. Calculate your carbon footprint now and take the first step in becoming an eco-conscious global citizen.",
    arrow_title: "Start your net zero journey ",
  };
  const arrow_title = { title: "Services", color: "black" };

  const top = {
    title: "Climate Risk Managmenet",
    text: "Elevate Your Company Towards a More Sustainable Future",
    href: "#form",
    img: cloudWhite
  };

  const climate = [
    {
      img: transition,
      title: "Transition Risk",
      text: "Transition Risk refers to the financial and operational risks associated with measures taken by organizations or communities to reduce carbon emissions and transition to sustainable practices. This risk encompasses the process of adapting to climate change and the journey toward a carbon-neutral future.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
    },

    {
      img: physical,
      title: "Physical Risk",
      text: "Asset Carbon Reduction Targets involve setting carbon reduction objectives for asset owners, aiming to reduce the carbon footprint of these assets. This section addresses the management of ownership and the implementation of carbon emission reduction goals within an organization's portfolio. Asset owners contribute to sustainability objectives and reap both financial and environmental benefits through their commitment to carbon reduction targets.",
      side: "left",
      hex_color: "rgba(255, 255, 255, 1)",
      btn: false,
      bg: true,
    },
  ];

  return (
    <div className="app">
      <Navbar />
      <TopInfo props={top} />
      {/* <Arrows arrow={arrow_title} /> */}
      <section>
        <div className="w-container">
          {climate.map((item) => (
            <Content props={item} />
          ))}
        </div>
      </section>
      <section id="form">
        <InputArea props={dash_form} />
      </section>
      <Footer />
    </div>
  );
}
