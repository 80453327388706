import React from "react";
import logo from "../../img/logo.png";
import phone from "../../img/call.png";
import email from "../../img/sms.png";
import c from "../../img/c.png";
import x from "../../img/x.png";
import facebook from "../../img/facebook.png";
import instagram from "../../img/instagram.png";
import linkedin from "../../img/linkedin.png";
import "./footer.scss";

export default function Footer() {
  return (
    <>
      <footer>
        <div className="w-container">
          <div className="footer">
            <div className="left-side">
              <div className="logo">
                <img src={logo} alt="logo" style={{ height: "60px" }} />
              </div>
              <div className="phone">
                <div className="phone-icon">
                  <img src={phone} alt="icon" />
                </div>
                <div className="phone-number text-color">+90 530 641 2255</div>
              </div>
              <div className="email">
                <div className="email-icon">
                  <img src={email} alt="icon" />
                </div>
                <div className="email-address text-color">info@crewnature.com</div>
              </div>
            </div>
            <div className="right-side">
              <div className="categories">
                <div className="companies-btns">
                  <div className="footer-title">Companies</div>
                  <a href="/">About</a>
                  <a href="/">Terms of Services</a>
                  <a href="/">Privacy Policy</a>
                  <a href="/">FAQ</a>
                  <a href="/blog">Blog</a>
                  <a href="/">Contact</a>
                </div>
                <div className="services-btns">
                  <div className="footer-title">Services</div>
                  <a href="/esganalytics">Analytics</a>
                  <a href="/carbonTrajectory">Carbon trajectory</a>
                  <a href="/climateRisk">Climate Risk Management</a>
                  <a href="/reporting">Reporting</a>
                  <a href="/natureRecovery">Nature Recovery</a>
                  <a href="/digitalTwin">Digital Twin</a>
                </div>
              </div>
            </div>
          </div>
          <div className="bottom">
            <div className="copy">
              <div className="copy-icon">
                <img src={c} alt="c" />
              </div>
              <div className="copy-text text-color">
                <span>copyright 2023 by Crewision. All Rights Reserved.</span>
              </div>
            </div>
            <div className="socials">
              <a href="./">
                <img src={x} alt="icon" />
              </a>
              <a href="./">
                <img src={facebook} alt="icon" />
              </a>
              <a href="./">
                <img src={instagram} alt="icon" />
              </a>
              <a href="./">
                <img src={linkedin} alt="icon" />
              </a>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
