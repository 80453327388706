import React, { useState, useEffect } from "react";
import "./corp.scss";
import { useMediaQuery } from "react-responsive";

export default function Corp({ img, className }) {
  const isBigScreen = useMediaQuery({ query: "(min-width: 1360px)" });
  const isMidScreen = useMediaQuery({ query: "(min-width: 1170px)" });
  const isSmallScreen = useMediaQuery({ query: "(min-width: 760px)" });
  const count =
    isBigScreen == true
      ? 4
      : isMidScreen == true
      ? 3
      : isSmallScreen == true
      ? 2
      : 1;


  const [firstIndex, setFirstIndex] = useState(0);
  const [lastIndex, setLastIndex] = useState(count);
  const backward = () => {
    if (firstIndex <= 0) {
      return;
    } else {
      setFirstIndex(firstIndex - 1);
      setLastIndex(lastIndex - 1);
      return;
    }
  };
  function forward() {
    if (lastIndex === img.length) {
      return;
    } else {
      setFirstIndex(firstIndex + 1);
      setLastIndex(lastIndex + 1);
      return;
    }
  }

  if (img.length > count) {
    var new_img = img.slice(firstIndex, lastIndex);
  }

  return (
    <>
      <section className="corparation">
        <div className="w-container">
          <div className="corp">
            <div
              className={className == "width" ? "icons width" : "icons height"}
            >
              {img.length > count
                ? new_img.map((i) => <img src={i} alt="aaaa" />)
                : img.map((i) => <img src={i} alt="aaaa" />)}
            </div>
          </div>
          {img.length > count && (
            <div className="arrow-btn">
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={backward}
              >
                <path
                  d="M15 19.9201L8.47997 13.4001C7.70997 12.6301 7.70997 11.3701 8.47997 10.6001L15 4.08008"
                  stroke={firstIndex > 0 ? "#292D32" : "#B1B1B1"}
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                onClick={forward}
              >
                <path
                  d="M8.91003 19.9201L15.43 13.4001C16.2 12.6301 16.2 11.3701 15.43 10.6001L8.91003 4.08008"
                  stroke={lastIndex < img.length ? "#292D32" : "#B1B1B1"}
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
        </div>
      </section>
    </>
  );
}
