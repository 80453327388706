import { Card } from "antd";
import React from "react";

const style = {
    backgroundColor: "rgba(240, 239, 255, 0.64)",
}

export default function Box({ title, children }) {
  return (
    <Card title={title} style={style}>
        {children}
    </Card>
  );
}
