import React from 'react';
import "../topMenu.scss"

function TopMenu(props) {
    return (
        <div>
            <ul className="nav nav-pills nav-justified">
                <li className="nav-item">
                    <a className="nav-link active" aria-current="page" href="#">Analytics</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Carbon trajectory</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href="#">Climate Risk Management</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href='#'>Reporting</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href='#'>Nature Recovery</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" href='#'>Digital Twin</a>
                </li>
            </ul>
        </div>
    );
}

export default TopMenu;