import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../style/wrapper.scss";
import Logo from "../img/logo.png";

function Login({ setIsLogin }) {
  const [email, setEmail] = useState("crewin@gmail.com");
  const [password, setPassword] = useState("crewin123");
  const navigate = useNavigate();

  const handleFormSubmit = (event) => {
    event.preventDefault();
    if (email === "crewin@gmail.com" && password === "crewin123") {
      setIsLogin(true);
      localStorage.setItem("isLogin", "true");
      navigate("/dashboard");
    }
  };

  return (
    <div
      style={{
        width: "25%",
        textAlign: "center",
        margin: "auto",
        marginTop: "30vh",
      }}
    >
      <a href="/">
        <img style={{ height: 70, marginBottom: 50 }} src={Logo} alt="aaa" />
      </a>
      <form onSubmit={handleFormSubmit}>
        <div className="mb-3">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email address
          </label>
          <input
            type="email"
            className="form-control"
            id="exampleInputEmail1"
            aria-describedby="emailHelp"
            // value={email}
            value={"crewin@gmail.com"}
            // onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="exampleInputPassword1" className="form-label">
            Password
          </label>
          <input
            type="password"
            className="form-control"
            id="exampleInputPassword1"
            // value={password}
            // onChange={(e) => setPassword(e.target.value)}
            value={"crewin123"}
          />
        </div>
        <button
          type="submit"
          className="btn btn-primary"
          style={{ backgroundColor: "#8200F4", borderColor: "#8200F4" }}
        >
          Login
        </button>
      </form>
    </div>
  );
}

export default Login;
