import React, { useState } from "react";
import Button from "../button";
import logo from "../../img/logo.png";
import "./navbar.scss";

export default function Navbar() {
  const [hover, setHover] = useState(false);
  const [mobilN, setMobilN] = useState(false);

  const btn = { text: "Login", width: "135px", color: "rgba(21, 54, 76, 1)" };
  return (
    <>
      <nav>
        <div className="w-container">
          <div className="navbar">
            <a href="/">
              <img src={logo} alt="logo" style={{height: "60px"}} />
            </a>
            <div className="nav-right">
              <div className="menu" style={{ marginRight: "30px" }}>
                <a href="/">Home</a>
                {/* <a>About</a> */}
                <div
                  className="services"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                >
                  <span className="span">Services</span>
                  <div
                    style={
                      hover === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    className="hover"
                  >
                    <a href="/esganalytics">Analytics</a>
                    <a href="/carbonTrajectory">Carbon trajectory</a>
                    <a href="/climateRisk">Climate Risk Management</a>
                    <a href="/reporting">Reporting</a>
                    <a href="/natureRecovery">Nature Recovery</a>
                    <a href="/digitalTwin">Digital Twin</a>
                  </div>
                </div>
                {/* <a>Pricing</a>   */}
                <a href="/blog">Blog</a>
                {/* <a>Case Studies</a> */}
              </div>
              <Button button={btn} variant="small" href={"/login"}/>
              <div
                className="mobil-nav-btn"
                onClick={() => setMobilN(mobilN == false ? true : false)}
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3 7H21"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3 12H21"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                  <path
                    d="M3 17H21"
                    stroke="#292D32"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        <div id="mobil-nav">
          <div className={mobilN == true ? "mobil-nav open" : "mobil-nav"}>
            <ul>
              <li>
                <a href="/" className="mobil-nav-menu-btn">
                  Home
                </a>
                {/* <a>Case Studies</a> */}
              </li>
              {/* <li><a>About</a></li> */}
              <li>
                <div
                  className="services mobil-nav-menu-btn"
                  onClick={() => setHover(hover == false ? true : false)}
                >
                  <span className="span">Services</span>
                  <div
                    style={
                      hover === true
                        ? { display: "block" }
                        : { display: "none" }
                    }
                    className="hover"
                  >
                    <ul className="services-ul-mobil">
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/esganalytics"
                        >
                          Analytics
                        </a>
                      </li>
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/carbonTrajectory"
                        >
                          Carbon trajectory
                        </a>
                      </li>
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/climateRisk"
                        >
                          Climate Risk Management
                        </a>
                      </li>
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/reporting"
                        >
                          Reporting
                        </a>
                      </li>
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/natureRecovery"
                        >
                          Nature Recovery
                        </a>
                      </li>
                      <li>
                        <a
                          className="services mobil-nav-menu-btn-li-btn"
                          href="/digitalTwin"
                        >
                          Digital Twin
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </li>
              {/* <li>
                <a  className="mobil-nav-menu-btn">Pricing</a>
              </li> */}
              <li>
                <a className="mobil-nav-menu-btn" href="/blog">
                  Blog
                </a>
              </li>
              {/* <li>
                <a  className="mobil-nav-menu-btn">Case Studies</a>
              </li> */}
              <li>
                <Button button={btn} variant="small" href={"/login"}/>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
