import React from "react";
import Button from "../button";
import "./content.scss";

export default function Content({ props }) {
  const btn = {
    text: "Read More",
    width: "150px",
    color:
      props.side === "right" ? "rgba(21, 54, 76, 1)" : "rgba(130, 0, 244, 1)",
    href: props.href,
  };

  return (
    <div
      className={props.bg === true ? "content bg-content" : "content"}
      style={
        props.side === "right"
          ? { flexDirection: "row" }
          : { flexDirection: "row-reverse" }
      }
    >
      <div
        className={`text-area ${props.side}`} 
      >
        <svg
          width="24"
          height="26"
          viewBox="0 0 24 26"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 0L23.2583 6.5V19.5L12 26L0.74167 19.5V6.5L12 0Z"
            fill={props.hex_color}
          />
        </svg>
        <div className="title">{props.title}</div>
        <div className="text text-color">{props.text}</div>
        {props.btn === true && <Button button={btn} />}
      </div>
      <div className="img-area">
        <img src={props.img} alt="img" />
      </div>
    </div>
  );
}
