import React from "react";
import Navbar from "../components/Navbar";
import TopInfo from "../components/TopInfo";
import Arrows from "../components/arrows";
import Content from "../components/Content";
import InputArea from "../components/inputArea";
import Footer from "../components/Footer";
import target from "../img/target.png";
import asset from "../img/asset.png";
import build from "../img/build.png";
import polyWhite from "../img/polygon-white.png"

export default function CarbonTrejectory() {
  const dash_form = {
    text: "Start your journey towards a greener future. Calculate your carbon footprint now and take the first step in becoming an eco-conscious global citizen.",
    arrow_title: "Start your net zero journey ",
  };
  const arrow_title = { title: "Services", color: "black" };

  const top = {
    title: "Carbon Trajectory",
    text: "Elevate Your Company Towards a More Sustainable Future",
    href: "#form",
    img: polyWhite,
  };

  const carbon = [
    {
      img: target,
      title: "Enterprise Carbon Reduction Targets",
      text: "Enterprise Carbon Reduction Targets are crucial for businesses to set and manage their carbon reduction objectives, contributing to a sustainable future. This section focuses on the establishment and oversight of carbon emission reduction goals within enterprises. Businesses aim to reduce carbon emissions in alignment with their sustainability strategies, not only minimizing their environmental impact but also achieving cost savings.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
    },

    {
      img: asset,
      title: "Asset Carbon Reduction Targets",
      text: "Asset Carbon Reduction Targets involve setting carbon reduction objectives for asset owners, aiming to reduce the carbon footprint of these assets. This section addresses the management of ownership and the implementation of carbon emission reduction goals within an organization's portfolio. Asset owners contribute to sustainability objectives and reap both financial and environmental benefits through their commitment to carbon reduction targets.",
      side: "left",
      hex_color: "rgba(255, 255, 255, 1)",
      btn: false,
      bg: true,
    },

    {
      img: build,
      title: "Sustainability Development Principles",
      text: "Sustainability Development Principles encompass the fundamental guidelines and values that drive sustainable practices and environmental stewardship within organizations. This section emphasizes the core principles, standards, and ethical considerations that guide the integration of sustainability into daily operations. It focuses on promoting responsible decision-making and holistic approaches to ensure long-term environmental and social well-being.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
      bg: false,
    },
  ];
  return (
    <div className="app">
      <Navbar />
          <TopInfo props={top} />

      {/* <Arrows arrow={arrow_title} /> */}

      <section>
        <div className="w-container">
          {carbon.map((item) => (
            <Content props={item} />
          ))}
        </div>
      </section>
      <section id="form">
        <InputArea props={dash_form} />
      </section>
      <Footer />
    </div>
  );
}
