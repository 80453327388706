import React, { useState } from "react";
import "../../../style/accordion.scss";
import {
  AppstoreOutlined,
  MailOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { Collapse, Menu } from "antd";
import RegionMenu from "../Menus/RegionMenu";


export default function Sidebar() {
  return (
    <div
      className={"sidebar"}
      style={{
        backgroundColor: "#f6f5ff",
        width: "270px",
        position: "fixed",
        top: 0,
        left: 0,
        height: "100vh",
        padding: "16px",
        paddingTop: "80px",
        zIndex: "999",
      }}
    >
      <div>
        <ul className="nav nav-underline nav-justified">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="#">
              Coğrafi
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="#">
              Custom
            </a>
          </li>
        </ul>
      </div>

      <RegionMenu/>
    </div>
  );
}
