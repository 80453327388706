import React from "react";
import "../App.scss";
import Navbar from "../components/Navbar";
import BlogCard from "../components/BlogCard";
import blog from "../img/blog.png";
import Footer from "../components/Footer";
import right_hex from "../img/right_hex.png";

export default function Blog() {
  const blogCard = [
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "primary",
    },
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "secondary",
    },
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "primary",
    },
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "secondary",
    },
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "primary",
    },
    {
      img: blog,
      title: "Sustainable Living Tips",
      text: 'Explore our "Sustainable Living Tips" blog for quick, actionable insights on leading a greener, more eco-friendly lifestyle.',
      type: "secondary",
    },
  ];
  return (
    <div className="app">
      <img
        src={right_hex}
        alt="hex"
        style={{
          position: "absolute",
          top: "-50px",
          right: "-610px",
          zIndex: "-11",
        }}
      />
      <div className="blog">
        <Navbar />
        <section>
          <div className="w-container">
            <div className="blog-title">Sustainable Living Tips</div>
            <div className="blog-text">
              Explore our "Sustainable Living Tips" blog for quick, actionable
              insights on leading a greener, more eco-friendly lifestyle.
              Discover practical ways to reduce your environmental footprint and
              make sustainable choices in your daily life. Join us on the path
              to a more eco-conscious future.
            </div>
          </div>
        </section>
        <section>
          <div className="w-container">
            <div className="grid">
              {blogCard.map((item) => (
                <BlogCard props={item} />
              ))}
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
}
