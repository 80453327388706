import React from "react";
import "../style/inputArea.scss";
import { Formik, Field, Form } from "formik";
import Arrows from "../components/arrows";
import bg_form from "../img/form-background.png";

export default function InputArea({ props }) {
  const title = props.arrow_title;
  const arrow = { title: title, color: "white" };
  return (
    <div
      className="form"
      style={{ backgroundImage: `url(${bg_form})`, width: "100%" }}
    >
      <div className="arrow">
        <Arrows arrow={arrow} />
      </div>
      <span className="form-text">{props.text}</span>
      <Formik
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          company: "",
          revenue: "",
          number: "",
          country: "",
          industry: "",
        }}
        onSubmit={async (values) => {
          await new Promise((r) => setTimeout(r, 500));
        }}
      >
        <Form>
          <div className="form-area">
            <div className="form-side">
              <div className="inputs-area">
                <div className="input-box">
                  <label htmlFor="firstName">First Name</label>
                  <Field id="firstName" name="firstName" className="field" />
                </div>
                <div className="input-box">
                  <label htmlFor="lastName">Last Name</label>
                  <Field id="lastName" name="lastName" className="field" />
                </div>
              </div>
              <div className="inputs-area">
                <div className="input-box">
                  <label htmlFor="company">Company</label>
                  <Field id="company" name="company" className="field" />
                </div>
                <div className="input-box">
                  <label htmlFor="revenue">Revenue</label>
                  <Field id="revenue" name="revenue" className="field" />
                </div>
              </div>
              <div className="inputs-area">
                <div className="input-box">
                  <label htmlFor="email">Corporote Email</label>
                  <Field
                    id="email"
                    name="email"
                    type="email"
                    className="field"
                  />
                </div>
                <div className="input-box">
                  <label htmlFor="number">Phone Number</label>
                  <Field
                    id="number"
                    name="number"
                    type="phone"
                    className="field"
                  />
                </div>
              </div>
              <div className="inputs-area">
                <div className="input-box">
                  <label htmlFor="country">Country</label>
                  <Field id="country" name="country" className="field" />
                </div>
                <div className="input-box">
                  <label htmlFor="industry">Industry</label>
                  <Field id="industry" name="industry" className="field" />
                </div>
              </div>
              <div className="inputs-area">
                <div className="input-box">
                  <label htmlFor="description">Description</label>
                  <Field
                    id="description"
                    name="description"
                    className="field"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="submit-btn-area">
            <button type="submit">Start your sustainability journey</button>
          </div>
        </Form>
      </Formik>
    </div>
  );
}
