import React from "react";

function PortfolioItems({ icon, name, count, type,changing }) {
  return (
    <div
      style={{
        margin: "40px 0",
      }}
    >
      <div
        style={{
          backgroundColor: "#F3E6FE",
          borderRadius: "50%",
          width: 64, 
          height: 64,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        {icon}
      </div>
      <div style={{ marginTop: "10px", color: "#777777" }}>{name}</div>
      <div
        style={{
          fontSize: "20px",
        }}
      >
        <strong>{count}</strong>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        {type == "down" ? (
          <i
            className="fa-solid fa-arrow-down"
            style={{
              color: "#00BA29",
              width: "24px",
              height: "24px",
            }}
          ></i>
        ) : (
          <i
            className="fa-solid fa-arrow-up"
            style={{
              color: "#F50000",
              width: "24px",
              height: "24px",
            }}
          ></i>
        )}
        <div
          style={{
            marginLeft: "6px",
            color: type == "down" ? "#00BA29" : "#F50000",
          }}
        >
          <strong>{changing}%</strong>
        </div>
      </div>
    </div>
  );
}

export default PortfolioItems;
