import React, { useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
function getItem(label, key, icon, children, type) {
  return {
    key,
    icon,
    children,
    label,
    type,
  };
}
const items = [
  getItem('Asya', 'sub1', null, [
    getItem('Çin', '1'),
    getItem('Tayvan', '2'),
    getItem('Kazakistan', '3'),
  ]),
  getItem('Avrupa', 'sub2', null, [
    getItem('Fransa', '5'),
    getItem('Almanya', 'sub3', null, [getItem('Almanya 1', '7'), getItem('Almanya 2', '8')]),
  ]),

];

// submenu keys of first level
const rootSubmenuKeys = ['sub1', 'sub2', 'sub4'];
const RegionMenu = () => {
  const [openKeys, setOpenKeys] = useState(['sub2']);
  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (latestOpenKey && rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };
  return (
    <Menu
      mode="inline"
      openKeys={openKeys}
      onOpenChange={onOpenChange}
      defaultSelectedKeys={['8']}
      defaultOpenKeys={['sub3']}

      style={{
        position: "relative"
      }}
      items={items}
    />
  );
};
export default RegionMenu;