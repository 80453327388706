import React from "react";
import "../App.scss";
import Content from "../components/Content";
import Navbar from "../components/Navbar";
import discover_img from "../img/discover.png";
import Button from "../components/button";
import Arrows from "../components/arrows";
import Corp from "../components/Corp";
import graph from "../img/graph.png";
import polygon from "../img/polygon.png";
import phone from "../img/phone.png";
import cloud from "../img/cloud.png";
import buildings from "../img/buildings.png";
import world from "../img/world.png";
import right_hex from "../img/right_hex.png";
import left_hex from "../img/left_hex.png";
import coban from "../img/coban.png";
import crewin from "../img/crewin.png";
import engelsiz from "../img/engelsiz.png";
import vision from "../img/vision.png";
import tcfd from "../img/tcfd.png";
import grı from "../img/grı.png";
import tnfd from "../img/tnfd.png";
import sasb from "../img/sasb.png";
import measure from "../img/measure.png";
import analyse from "../img/analyse.png";
import predict from "../img/predict.png";
import tablet from "../img/tablet.png";
import reduce from "../img/reduce.png";
import InfoBox from "../components/InfoBox";
import InputArea from "../components/inputArea";
import Footer from "../components/Footer";
function Home() {
  const top_corp = [coban, crewin, engelsiz, vision];
  const bottom_corp = [tcfd, grı, tnfd, coban, sasb, crewin, engelsiz, vision];

  const home = [
    {
      img: graph,
      title: "ESG Analytics: Data-Driven Solutions for Sustainability",
      text: "ESG Analytics offers a data-centric solution to help you achieve your sustainability goals. Optimize your company's ESG performance and shape a sustainable future.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: true,
      bg: false,
      href: "/esganalytics",
    },
    {
      img: polygon,
      title: "Carbon Trajectory: A Sustainable Path to a Greener Future",
      text: "Carbon Trajectory provides a roadmap for planning the steps your company or organization can take to reduce its carbon footprint and move towards a sustainable future. Get guidance to achieve your carbon-neutral goals.",
      side: "left",
      hex_color: "rgba(130, 0, 244, 1)",
      btn: true,
      bg: false,
      href: "/carbonTrajectory",
    },
    {
      img: phone,
      title:
        "ESG Reporting: Transparently Showcasing Your Sustainability and Responsibility Footprint",
      text: "ESG reporting is a powerful tool to monitor your company's environmental, social, and governance performance and transparently present it to your stakeholders. We provide a platform to highlight your sustainability commitments and achievements.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: true,
      bg: false,
      href: "/reporting",
    },
    {
      img: cloud,
      title:
        "Climate Risk Management: Navigating the Challenges of a Changing Climate",
      text: "Climate risk management is a critical process that enables companies to protect themselves against climate change-related risks while also evaluating opportunities. This content discusses the importance of understanding and managing climate change-related risks and opportunities.",
      side: "left",
      hex_color: "rgba(130, 0, 244, 1)",
      btn: true,
      bg: false,
      href: "/climateRisk",
    },
    {
      img: buildings,
      title:
        "Digital Twins: The Perfect Fusion of the Physical and Digital Worlds",
      text: "Digital twins are virtual replicas of physical assets and processes. This innovation is helping industries enhance efficiency, solve problems faster, and make better decisions about the future. This content discusses what digital twins are, how they work, and the advantages they offer.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: true,
      bg: false,
      href: "/digitalTwin",
    },

    {
      img: world,
      title: "Nature Recovery: Restoring Our Connection with the Natural World",
      text: "Nature Recovery is a movement aimed at reconnecting people with the natural world and rebuilding biodiversity. This content highlights the importance of nature recovery and explores what can be done in this process.",
      side: "left",
      hex_color: "rgba(130, 0, 244, 1)",
      btn: true,
      bg: false,
      href: "/natureRecovery",
    },
  ];

  const btn = {
    text: "Start your net zero journey",
    color: "rgba(21, 54, 76, 1)",
    href: "#form",
  };
  const arrow_title = { title: "Services", color: "black" };
  const arrow_text = { title: "We are in-line with", color: "black" };
  const dash_form = {
    text: "We would love to hear from you! Please reach out to us by filling the form below to start your sustainability journey.",
    arrow_title: "Let’s Achieve A Greener Future Together.",
  };

  const first_box = {
    img: measure,
    title: "Measure",
    text: "Measuring sustainability performance can pose challenges, but our expertise, cultivated over a decade in the realm of corporate sustainability management, is evident in the solutions we offer. We help you establish your scope, maintain alignment with sustainability frameworks, oversee user management, and procure top-tier performance data.",
  };

  const second_box = {
    img: analyse,
    title: "Analyse",
    text: "Through our platform, you can rely on the integrity of your ESG data. Our AI-powered data consistency tests and precise data processing empower you to achieve your corporate objectives. Our solutions are versatile, serving both internal and external needs. You'll have complete command over your sustainability data, enabling you to extract valuable insights.",
  };

  const third_box = {
    img: predict,
    title: "Predict",
    text: "Reviewing historical data is straightforward, but what about forecasting the future? Leveraging the cutting-edge capabilities of modern data science technologies, we can now anticipate your future performance in critical sustainability metrics. This provides you with an early warning system for your corporate objectives, enabling you to adapt your strategy proactively and identify both risks and opportunities.",
  };

  const fourth_box = {
    img: tablet,
    title: "Report",
    text: "Measuring sustainability performance can pose challenges, but our expertise, cultivated over a decade in the realm of corporate sustainability management, is evident in the solutions we offer. We help you establish your scope, maintain alignment with sustainability frameworks, oversee user management, and procure top-tier performance data.",
  };

  const fifth_box = {
    img: reduce,
    title: "Reduce",
    text: "Measuring sustainability performance can pose challenges, but our expertise, cultivated over a decade in the realm of corporate sustainability management, is evident in the solutions we offer. We help you establish your scope, maintain alignment with sustainability frameworks, oversee user management, and procure top-tier performance data.",
  };

  return (
    <div className="app ">
      <div
        style={{
          position: "absolute",
          top: "1450px",
          zIndex: "-1",
        }}
        className="home-abs-img home-abs-right-img"
      ></div>
      <div
        style={{
          position: "absolute",
          top: "3400px",
          zIndex: "-1",
        }}
        className="home-abs-img home-abs-left-img"
      ></div>
      <Navbar />
      <section>
        <div className="w-container">
          <div className="header-content">
            <div className="text-area">
              <div className="title">
                Your Journey Towards Carbon Neutrality
              </div>
              <Button button={btn} />
            </div>
            <div className="img-area">
              <img src={discover_img} alt="discover" />
            </div>
          </div>
        </div>
      </section>
      <Corp img={top_corp} className={"width"} />
      <Arrows arrow={arrow_title} />
      <section>
        <div className="w-container">
          {home.map((item) => (
            <Content props={item} />
          ))}
        </div>
      </section>
      <section>
        <div className="gray-area">
          <div className="w-container">
            <Arrows arrow={arrow_text} />
            <Corp img={bottom_corp} className={"height"} />
          </div>
        </div>
      </section>
      <section>
        <div className="w-container">
          <div className="info-box-area">
            <InfoBox props={first_box} />
            <InfoBox props={second_box} />
            <InfoBox props={third_box} />
            <InfoBox props={fourth_box} />
            <InfoBox props={fifth_box} />{" "}
          </div>
        </div>
      </section>
      <section id="form">
        <InputArea props={dash_form} />
      </section>
      <Footer />
    </div>
  );
}

export default Home;
