import React from "react";
import Logo from '../../../img/logo.png'

function Header({ isLogin, setIsLogin }) {
  const logoutFunc = () => {
    setIsLogin(false);
    localStorage.setItem('isLogin','false')
  };
  return (
    <div  style={{position: "fixed", width: "100%", zIndex:"99999"}}>
      <nav className="navbar navbar-expand-lg bg-body-tertiary">
        <a className="navbar-brand" href="#">
          <img style={{height:50,marginLeft:20}} src={Logo} alt="aaa" />
        </a>
        <div className="dropdown" style={{position:'absolute',right:10}}>
          <button className="btn" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i className="fa-regular fa-user" style={{marginRight:'10px',fontSize:'20px'}}></i>
            Crewin
          </button>
          <ul className="dropdown-menu">
            <li className={'dropdown-item'}>Profile</li>
            <li className={'dropdown-item'} onClick={logoutFunc}>Logout</li>
          </ul>
        </div>

      </nav>


    </div>
  );
}

export default Header;
