import React from 'react'
import './infoBox.scss'


export default function InfoBox({props}) {
  return (
    <div className='info-box'>
      <div className='img-area'>
          <img src={props.img} alt='info'/>
      </div>
      <div className='text-area'>
        <div className='title'>{props.title}</div>
        <div className='text-color'>{props.text}</div>
      </div>
    </div>
  )
}
