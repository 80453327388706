import React from "react";
import Navbar from "../components/Navbar";
import TopInfo from "../components/TopInfo";
import Arrows from "../components/arrows";
import Content from "../components/Content";
import InputArea from "../components/inputArea";
import Footer from "../components/Footer";
import passaport from "../img/passaport.png";
import modelling from "../img/modelling.png";
import vr from "../img/vr.png";
import buildingsWhite from "../img/buildings-white.png";

export default function CarbonTrejectory() {
  const dash_form = {
    text: "Start your journey towards a greener future. Calculate your carbon footprint now and take the first step in becoming an eco-conscious global citizen.",
    arrow_title: "Start your net zero journey ",
  };
  const arrow_title = { title: "Services", color: "black" };

  const top = {
    title: "Digital Twin",
    text: "Elevate Your Company Towards a More Sustainable Future",
    href: "#form",
    img: buildingsWhite,
  };

  const digital = [
    {
      img: passaport,
      title: "Asset Passport",
      text: "Enterprise Reporting, organizations transparently share their sustainability and responsibility efforts, providing stakeholders with a comprehensive overview of their commitments and impact. It serves as a crucial tool for accountability, enabling businesses to showcase their dedication to sustainable practices and social responsibility.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
    },

    {
      img: modelling,
      title: "3D Modelling",
      text: "Climate Disclosures refer to the process where organizations disclose climate-related data and carbon footprints, illustrating their resource utilization and impact in combating climate change. It serves as a means for businesses to proactively communicate their commitment to addressing environmental challenges.",
      side: "left",
      hex_color: "rgba(255, 255, 255, 1)",
      btn: false,
      bg: true,
    },

    {
      img: vr,
      title: "Immersive Virtual Reality",
      text: "ESG Reporting involves organizations transparently reporting their Environmental, Social, and Governance (ESG) performance, providing stakeholders with a holistic view of their sustainability initiatives and responsible practices. It serves as a comprehensive tool for evaluating an organization's ethical and sustainable commitments.",
      side: "right",
      hex_color: "rgba(21, 54, 76, 1)",
      btn: false,
      bg: false,
    },
  ];

  return (
    <div className="app">
      <Navbar />
      <TopInfo props={top} />
      {/* <Arrows arrow={arrow_title} /> */}
      <section>
        <div className="w-container">
          {digital.map((item) => (
            <Content props={item} />
          ))}
        </div>
      </section>
      <section id="form">
        <InputArea props={dash_form} />
      </section>
      <Footer />
    </div>
  );
}
