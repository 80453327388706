import React from "react";
import "./blogCard.scss";
import search_prim from "../../img/search_prim.png";
import search_second from "../../img/search_second.png";

export default function BlogCard({ props }) {
  return (
    <a
      href="/"
      className={props.type === "primary" ? "card" : "card secondary"}
    >
      <div
        className="img-area"
        style={{ backgroundImage: `url(${props.img})` }}
      >
        <div className="detail-img">
          <img src={search_second} alt="search" />
        </div>
      </div>
      <div className="text-area">
        <div className="title">{props.title}</div>
        <div className="text">{props.text}</div>
      </div>
    </a>
  );
}
