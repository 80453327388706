import React from "react";
import Button from "../button";
import "./topInfo.scss";

export default function TopInfo({ props }) {
  const btn = {
    text: "Start your net zero journey",
    width: "275px",
    color: "rgba(21, 54, 76, 1)",
    href: props.href,
  };

  return (
    <section id="topInfo">
      <div className="w-container">
        <div className="top-info">
          <div className="text-area">
            <div className="title">{props.title}</div>
            {/* <div className="sub-title">{props.subTitle}</div> */}
            <div className="text">{props.text}</div>
            <Button button={btn} />
          </div>
          <div className="absolute-img">
            <img src={props.img} />
          </div>
        </div>
      </div>
    </section>
  );
}
