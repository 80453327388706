import Card from "antd/es/card/Card";
import React, { PureComponent } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import Box from "../Box";

const data = [
  {
    name: 0,
    Electricity: 400,
    Fuels: 240,
    DistrictEnergy: 240,
    Water: 240,
    amt: 240,
  },
  {
    name: 1,
    Electricity: 300,
    Fuels: 139,
    DistrictEnergy: 510,
    Water: 200,
    amt: 221,
  },
  {
    name: 2,
    Electricity: 200,
    Fuels: 980,
    DistrictEnergy: 200,
    Water: 180,
    amt: 229,
  },
  {
    name: 3,
    Electricity: 278,
    Fuels: 390,
    DistrictEnergy: 790,
    Water: 160,
    amt: 200,
  },
  {
    name: 4,
    Electricity: 189,
    Fuels: 480,
    DistrictEnergy: 140,
    Water: 180,
    amt: 218,
  },
  {
    name: 5,
    Electricity: 239,
    Fuels: 380,
    DistrictEnergy: 240,
    Water: 140,
    amt: 250,
  },
  {
    name: 6,
    Electricity: 349,
    Fuels: 430,
    DistrictEnergy: 400,
    Water: 100,
    amt: 210,
  },
  {
    name: 7,
    Electricity: 278,
    Fuels: 390,
    DistrictEnergy: 690,
    Water: 120,
    amt: 200,
  },
  {
    name: 8,
    Electricity: 189,
    Fuels: 480,
    DistrictEnergy: 120,
    Water: 80,
    amt: 218,
  },
  {
    name: 9,
    Electricity: 239,
    Fuels: 380,
    DistrictEnergy: 240,
    Water: 60,
    amt: 250,
  },
];

function GraphicsOneComponent({title, props}) {
  return (
    <Box title={"Carbon to Energy Ratio"}>
        <LineChart
          width={900}
          height={300}
          data={data}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Legend />
          <Line
            type="monotone"
            dataKey="Fuels"
            stroke="#0088FE"
            activeDot={{ r: 8 }}
          />
          <Line type="monotone" dataKey="Electricity" stroke="#00C49F" />
          <Line type="monotone" dataKey="DistrictEnergy" stroke="#FFBB28" />
          <Line type="monotone" dataKey="Water" stroke="#FF8042" />
        </LineChart>
    </Box>
  );
}

export default GraphicsOneComponent;
