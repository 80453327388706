import { Col, Row } from "antd";
import React from "react";
import Header from "../../components/Dashboard/Header";
import GraphicsCircularComponent from "../../components/Dashboard/Charts/GraphicsCircularComponent";
import GraphicsOneComponent from "../../components/Dashboard/Charts/GraphicsOneComponent";
import TopMenu from "../../components/Dashboard/Menus/TabMenu";
import TabMenuBasic from "../../components/Dashboard/Menus/TabMenuBasic";
import PortfolioItems from "../../components/Dashboard/PortfolioItems/PortfolioItems";
import Sidebar from "../../components/Dashboard/Sidebar";

function DashBoard({ isLogin, setIsLogin }) {
  const overviews = [
    {
      name: "Electric",
      count: 278.219,
      change: "up",
      changeValue: "-4,2%",
      img: "electric",
    },
    {
      name: "Fuels",
      count: 29.191,
      change: "up",
      changeValue: "-2,0%",
      img: "electric",
    },
    {
      name: "District Energy",
      count: 1.563,
      change: "up",
      changeValue: "-4,5%",
      img: "electric",
    },
    {
      name: "Water",
      count: 7.87,
      change: "down",
      changeValue: "3.0%",
      img: "electric",
    },
    {
      name: "Waste",
      count: 278.219,
      change: "up",
      changeValue: "4,2%",
      img: "electric",
    },
    {
      name: "Fugitives",
      count: 278.219,
      change: "up",
      changeValue: "4,2%",
      img: "electric",
    },
    {
      name: "Total",
      count: 278.219,
      change: "up",
      changeValue: "4,2%",
      img: "electric",
    },
  ];
  return (
    <div>
      <Header setIsLogin={setIsLogin} />
      <Sidebar />
      <div className="wrapper">
        <div className={"row"}>
          <div
            className={"col-sm-12"}
            style={{
              padding: "10px",
            }}
          >
            <TopMenu />
            <TabMenuBasic />
          </div>
          <div
            className={"col-sm-12"}
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <PortfolioItems
              icon={
                <i
                  className="fa-solid fa-bolt"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Electricity"}
              count={278.219}
              type={"down"}
              changing={-4.2}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-regular fa-image"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Fuels"}
              count={29.191}
              type={"down"}
              changing={-2.0}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-solid fa-building-circle-arrow-right"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"District Energy"}
              count={1.563}
              type={"down"}
              changing={-4.5}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-solid fa-droplet"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Water"}
              count={7.87}
              changing={3.0}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-solid fa-trash"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Waste"}
              count={31}
              type={"down"}
              changing={-6.6}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-regular fa-image"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Fugitives"}
              count={290}
              type={"down"}
              changing={-2.2}
            />
            <PortfolioItems
              icon={
                <i
                  className="fa-regular fa-image"
                  style={{ color: "rgb(130, 0, 244)" }}
                ></i>
              }
              name={"Total"}
              count={317.763}
              type={"down"}
              changing={-3.8}
            />
          </div>
          <Row gutter={24}>
            <Col span={16}>
                <GraphicsOneComponent/>
            </Col>
            <Col span={8}>
                <GraphicsCircularComponent />
            </Col>
          </Row>
          
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
