import React, { useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Router from "./routes";
import Login from "./pages/Login";
import DashBoard from "./pages/dashboard/DashBoard";
import Home from "./pages/Home";
import Analytics from "./pages/Analytics";
import Blog from "./pages/Blog";
import CarbonTrejectory from "./pages/CarbonTrejectory";
import ClimateRisk from "./pages/ClimateRisk";
import DigitalTwin from "./pages/DigitalTwin";
import NatureRecovery from "./pages/NatureRecovery";
import Reporting from "./pages/Reporting";
import DashboardCarbonTrajectory from "./pages/dashboard/DashboardCarbonTrajectory";

export default function App() {
  const [isLogin, setIsLogin] = useState(false);

  useEffect(() => {
    const login = localStorage.getItem("isLogin");
    if (login === "true") {
      setIsLogin(true);
    }
  }, []);

  useEffect(() => {
    const login = localStorage.getItem("isLogin");
    if (login === "true") {
      setIsLogin(true);
    } else if (login === "false") {
      setIsLogin(false);
    } else {
      setIsLogin(false);
    }
  }, [isLogin]);
  return (
    <div className="App">
      <Routes>
        <Route
          path="/login"
          element={
            isLogin ? (
              <Navigate to="/dashboard" />
            ) : (
              <Login setIsLogin={setIsLogin} />
            )
          }
        />
        <Route
          path="/dashboard"
          element={
            isLogin ? (
              <DashBoard isLogin={isLogin} setIsLogin={setIsLogin} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route
          path="/dashboard/carbonTrajectory"
          element={
            isLogin ? (
              <DashboardCarbonTrajectory isLogin={isLogin} setIsLogin={setIsLogin} />
            ) : (
              <Navigate to="/login" />
            )
          }
        />
        <Route path="/" element={<Home />} />
        <Route path="/esganalytics" element={<Analytics />} />
        <Route path="/blog" element={<Blog />} />
        <Route path="/carbonTrajectory" element={<CarbonTrejectory />} />
        <Route path="/climateRisk" element={<ClimateRisk />} />
        <Route path="/digitalTwin" element={<DigitalTwin />} />
        <Route path="/natureRecovery" element={<NatureRecovery />} />
        <Route path="/reporting" element={<Reporting />} />
      </Routes>
    </div>
  );
}
